.profile-container {
    width: max-content;
    display: inline;
}

.category-weight {
    width: 70px;
}

.profilePreview {
    display: inline-block;
    width: 75%;
}

.profileList {
    display: inline-block;
    width: 25%;
    left: auto;
    vertical-align: top;
    z-index: -1;
}

.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}